import {useState} from "react";
import {customAxios} from "../customAxios";


export function useNectIdent() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<string>();
    const [error, setError] = useState<Error>();

    async function execute(orderId: number, stepId: number) {
        try {
            setLoading(true);
            const res = await ident(orderId, stepId)
            setData(res)
            return;
        }
        catch (e) {
            setError(error);
            // return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {loading, data, error, execute,};
}

export async function ident(orderId: number, stepId: number): Promise<string> {
    const response = await customAxios.put<string>(`/v1/order/${orderId}/step/${stepId}/nect-ident`);
    return response.data;
}


export function useNectSign() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<string>();
    const [error, setError] = useState<Error>();

    async function execute(orderId: number, stepId: number) {
        try {
            setLoading(true);
            const res = await sign(orderId, stepId)
            setData(res)
            return;
        }
        catch (e) {
            setError(error);
            // return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {loading, data, error, execute,};
}

export async function sign(orderId: number, stepId: number): Promise<string> {
    const response = await customAxios.put<string>(`/v1/order/${orderId}/step/${stepId}/nect-sign`);
    return response.data;
}



export function useNectCheckIdent() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<boolean>();
    const [error, setError] = useState<Error>();

    async function execute(orderId: number, stepId: number) {
        try {
            setLoading(true);
            const res = await checkIdent(orderId, stepId)
            setData(res)
            return;
        }
        catch (e) {
            setError(error);
            // return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {loading, data, error, execute,};
}

export async function checkIdent(orderId: number, stepId: number): Promise<boolean> {
    const response = await customAxios.get<boolean>(`/v1/order/${orderId}/step/${stepId}/nect-ident-check`);
    return response.data;
}


export function useNectCheckSign() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<boolean>();
    const [error, setError] = useState<Error>();

    async function execute(orderId: number, stepId: number) {
        try {
            setLoading(true);
            const res = await checkSign(orderId, stepId)
            setData(res)
            return res;
        }
        catch (e) {
            setError(error);
            // return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {loading, data, error, execute,};
}

export async function checkSign(orderId: number, stepId: number): Promise<boolean> {
    const response = await customAxios.get<boolean>(`/v1/order/${orderId}/step/${stepId}/nect-sign-check`);
    return response.data;
}