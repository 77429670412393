import React, {useState} from "react";
import {CommonStepProps} from "./DynamicStepComponentRenderer";
import {Button} from "react-bootstrap";
import ConfirmModal from "../../modals/ConfirmModal";
import {useTranslation} from "react-i18next";
import {useStepFunctions} from "../../../api/steps/useStepFunctions";
import notifier from "simple-react-notifications2";
import {useNectCheckSign} from "../../../api/steps/useNectStepFunctions";


export function CheckNect(props: CommonStepProps) {
    const {t, i18n} = useTranslation();

    const {loading: lChcek, data: dCheck, error: erCheck, execute: exCheck,} = useNectCheckSign();



    function executeConfirm() {
        exCheck(props.orderId, props.step.status?.id!!).then(res => {
            notifier.success("Response: " + res)
            props.reload()
        })
    }

    return (
        <>
            <Button disabled={lChcek} variant="primary" onClick={() => executeConfirm()}>
                {"Check signed"}
            </Button>
        </>
    )
}