import {OrderWorkflowStep} from "../../../model/order/orderWorkflow";
import React from "react";
import {ClickConfirm} from "./ClickConfirm";
import {Execute} from "./Execute";
import {CheckNect} from "./CheckNect";


export interface CommonStepProps {
    step: OrderWorkflowStep;
    orderId: number;
    reload: () => void;
}
type ComponentMap<P = {}> = {
    [key: string]: React.ComponentType<P>;
};

const components: ComponentMap<CommonStepProps> = {
    'ADMIN_CLICK_CONFIRM' : ClickConfirm,
    'ADMIN_EXECUTE' : Execute,
    'LEASING_ACTIVE' : ClickConfirm,
    'NECT_SIGN' : CheckNect,
};

export function DynamicTenantStepComponentRenderer(props:  CommonStepProps) {

    // const Component = components[props.step.stepType];
    const Component = getComponent(props.step);

    if (Component) {
        return (
            <>
                <Component {...props} />
            </>
        );
    } else {
        return <></>;
    }

}

function getComponent(step: OrderWorkflowStep): React.ComponentType<CommonStepProps> | undefined{
    if(step.stepType === 'NECT_SIGN') return CheckNect
    if(step.adminEnabled) {
        return ClickConfirm
    }
    if (step.backgroundExecutable) {
        return Execute
    }
    return undefined
}